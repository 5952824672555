import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import ChooserBlock from "../components/chooserBlock";
import Header from "../components/header";
import {Helmet} from "react-helmet";
import '../styles/theme/global.css'
import ReactGA from 'react-ga';

function GoogleAnalytics() {
    if (typeof window !== `undefined`) {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());

        gtag('config', 'G-EMHTZJ5DK6');
    }
}


export default function Home({data}) {
    const homeseo = data.strapiHomeSeo
    return (
        <>
            <Helmet>
                <html lang={"fr"}/>
                <title>Ebisu Records - Accueil</title>
                <link rel="stylesheet" href="../styles/theme/global.css"/>
                <meta name={"description"} content={homeseo.DescriptionSeo}/>
                <meta property={"og:title"} content={homeseo.TitleSeo}/>
                <meta property={"og:type"} content={"website"}/>
                <meta property={'og:image'} content={`https://www.ebisurecords.fr${homeseo.ImageSeo.publicURL}`}/>
                <meta property={'og:description'} content={homeseo.DescriptionSeo}/>
                <meta property={"og:url"} content={'https://ebisurecords.fr'}/>
                <meta property={"og:site_name"} content={"ebisurecords"}/>
                <meta property={"og:locale"} content={"fr_FR"}/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={homeseo.TitleSeo}/>
                <meta property="twitter:description" content={homeseo.DescriptionSeo}/>
                <meta property="twitter:image"
                      content={`https://www.ebisurecords.fr${homeseo.ImageSeo.publicURL}`}/>

                <link rel={"canonical"} href={"https://ebisurecords.fr"}/>
                <link rel={"icon"} href="/favicon.ico" type="image/x-icon"/>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-EMHTZJ5DK6"></script>
                <script>
                    {GoogleAnalytics()}
                </script>
            </Helmet>
            <>
                <Header data={data}/>
                <Layout className={'ignore-main ignore-padding z-index-fix'}>
                    <ChooserBlock data={data}/>
                </Layout>
            </>
        </>
    )
}

export const HomeQuery = graphql`
    query HomeQuery {
      strapiHomeSeo {
        ImageSeo {
          publicURL
        }
        DescriptionSeo
        TitleSeo
      }
      strapiLoading {
          LogoLoading {
              publicURL
          }
      }
      strapiThemeChooser {
          Themes {
              button
              slug
              id
              title
              imageChooser {
                  publicURL
              }
          }
      }
      strapiHeader {
          id
          Logo {
              publicURL
          }
          ItemsNav {
              id
              Item
        }
      }
    }
`


